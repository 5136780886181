import knustLogo from "../../../assets/svgs/knust_logo.svg";
import dipperlabLogo from "../../../assets/svgs/dipperlab_logo.svg";
import innovateUkLogo from "../../../assets/svgs/innovate_uk_logo.svg";
import ktpLogo from "../../../assets/svgs/ktp_logo.svg";
import mmuLogo from "../../../assets/svgs/mmu_logo.svg";
import sesiLogo from "../../../assets/svgs/sesi_logo.svg";

export default function SponsorsSection() {
  return (
    <div>
      {/* <h2 style={{ textAlign: "center", marginBottom: "2rem" }}>
        Meet the sponsors
      </h2> */}
      <div
        style={{
          gap: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center ",
          flexWrap: "wrap"
        }}
      >
        <img src={knustLogo} alt="knust logo" />
        <img src={dipperlabLogo} alt="knust logo" />
        <img src={innovateUkLogo} alt="knust logo" />
        <img src={ktpLogo} alt="knust logo" />
        <img src={mmuLogo} alt="knust logo" />
        <img src={sesiLogo} alt="knust logo" />
      </div>
    </div>
  );
}
