import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import LargeScreenContainer from "../../components/LargeScreenContainer";
import TrainingForm from "./components/TrainingForm";
import TrainingIntro from "./components/TrainingIntro";
import SponsorsSection from "./components/SponsorsSection";


function EventsMain() {
  return (
    <>
      <Navigation />
      {/* {isLoggedIn && <AdminNavigation />} */}
      <LargeScreenContainer>
        <TrainingIntro />
        <TrainingForm />
        {/** logos container */}
        <SponsorsSection/>
      </LargeScreenContainer>
      <Footer />
    </>
  );
}

export default EventsMain;
