import React, { useState, useRef } from "react";
import SingleSelect from "../../../components/SingleSelect";
import { AnimatePresence } from "framer-motion";
import Notification from "../../../components/Notification";
import "../training_styles.css";
import { endpoints } from "../../../services/Endpoints";
import axios from "axios";

const ratingOptions = [
  { name: "1 - lowest", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5 - highest", value: "5" },
];

const yesnoOptions = [
  {
    name: "Yes",
    value: "yes",
  },
  { name: "No", value: "no" },
  { name: "N/A", value: "n/a" },
];

const yesnoOptionsReq = [
  {
    name: "Yes",
    value: "yes",
  },
  { name: "No", value: "no" },
];

function TrainingForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [isError, setIsError] = useState<boolean>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>();
  const [validationResults, setValidationResults] = useState<{
    [key: string]: boolean;
  }>({});
  const validationRef = useRef({});

  const validateFormData = (data: { [key: string]: any }) => {
    validationRef.current = {
      name: "name" in data && !!data["name"],
      contact: "contact" in data && !!data["contact"],
      programme: "programme" in data && !!data["programme"],
      year: "year" in data && !!data["year"],
      electronics_rate:
        "electronics_rate" in data && !!data["electronics_rate"],
      build_controller_before:
        "built_controller_project" in data &&
        !!data["built_controller_project"],
      used_pcb_designing_before:
        "used_pcb_designing_before" in data &&
        !!data["used_pcb_designing_before"],
      board_prototyped_before:
        "board_prototyped_before" in data && !!data["board_prototyped_before"],
      fyp_IOT: "fyp_IOT" in data && !!data["fyp_IOT"],
      iot_rating: "iot_rating" in data && !!data["iot_rating"],
      why_want_to_join:
        "why_want_to_join" in data && !!data["why_want_to_join"],
    };
    setValidationResults((prev) => {
      return validationRef.current;
    });

    // setIsError(true);
    // setShowNotification(true)
  };

  const setFormField = (name: string, value: string) => {
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    validateFormData(formData);


    if (
      Object.keys(formData).length === 0 ||
      !Object.values(validationRef.current).every((value) => value)
    ) {
      setIsError(true);
      setShowNotification(true);
      setNotificationMessage("Please fix form errors");
      setIsLoading(false);
      return;
    } else {
      setShowNotification(false);
    }

    axios
      .post(`${endpoints.baseURL}${endpoints.applyForTraining}`, formData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.status === 200) {
          setIsError(false);
          setShowNotification(true);
          setNotificationMessage("Application successful");
        } else {
          setIsError(true);
          setShowNotification(true);
          setNotificationMessage("Problem encountered, please try again");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsError(true);
        setShowNotification(true);
        setNotificationMessage("Problem encountered, please try again");
        setIsLoading(false);
      });

    // setIsLoading(false);

    // send data to back
  };

  return (
    <>
      <AnimatePresence>
        {showNotification && (
          <Notification
            message={notificationMessage ?? ""}
            errorNotification={!!isError}
          />
        )}
      </AnimatePresence>
      <div id="training-form">
        <div
          className="inputs-card-main-container"
          style={{ margin: "2rem auto", maxHeight: "fit-content" }}
        >
          <div className="inputs-card-header" style={{ textAlign: "center" }}>
            Take your first step in becoming a master in the <br /> Internet of
            Things
          </div>
          <div className="inputs-card-form-inputs-container">
            <form onSubmit={handleSubmit} style={{ gap: "2rem" }}>
              <div className="t-form-field">
                <label className="t-field-label">Full Name</label>
                <input
                  placeholder={"Enter your full name"}
                  name="name"
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFormField(event.target.name, event.target.value)
                  }
                />
                {"name" in validationResults && !validationResults["name"] && (
                  <div className="t-form-error">
                    Please enter your full name
                  </div>
                )}
              </div>
              <div className="t-form-field">
                <label className="t-field-label">Phone Number</label>
                <input
                  placeholder="Enter your phone number"
                  name="contact"
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFormField(event.target.name, event.target.value)
                  }
                />
                {"contact" in validationResults &&
                  !validationResults["contact"] && (
                    <div className="t-form-error">
                      Please enter a valid phone number
                    </div>
                  )}
              </div>
              <div className="t-form-field">
                <label className="t-field-label">Program of study</label>
                <input
                  placeholder="Program (if you're not a student, just enter N/A)"
                  name="programme"
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFormField(event.target.name, event.target.value)
                  }
                />
                {"programme" in validationResults &&
                  !validationResults["programme"] && (
                    <div className="t-form-error">
                      Please enter a valid phone number
                    </div>
                  )}
              </div>
              <div className="t-form-select-field">
                <div>
                  <label>Year</label>
                  <SingleSelect
                    // defaultValue="straight"
                    style={{ width: "100%" }}
                    options={[
                      { name: "1", value: "1" },
                      { name: "2", value: "2" },
                      { name: "3", value: "3" },
                      { name: "4", value: "4" },
                      { name: "5", value: "5" },
                      { name: "6", value: "6" },
                      { name: "Not a Student", value: "n/a" },
                    ]}
                    placeholder="Year"
                    name="year"
                    onChange={(value) => setFormField("year", value)}
                  />
                </div>
                {"year" in validationResults && !validationResults["year"] && (
                  <div className="t-form-error">Please select a value</div>
                )}
              </div>
              <div className="t-form-select-field">
                <div>
                  <label>Rate Your Knowledge in electronics</label>
                  <SingleSelect
                    // defaultValue="straight"
                    style={{ width: "100%" }}
                    options={ratingOptions}
                    placeholder="Select your rating"
                    onChange={(value) =>
                      setFormField("electronics_rate", value)
                    }
                  />
                  {"electronics_rate" in validationResults &&
                    !validationResults["electronics_rate"] && (
                      <div className="t-form-error">Please select a value</div>
                    )}
                </div>
              </div>
              <div className="t-form-select-field">
                <div>
                  <label>
                    Have you built an electronic project with a microcontroller
                    before?
                  </label>
                  <SingleSelect
                    // defaultValue="straight"
                    style={{ width: "100%" }}
                    options={yesnoOptionsReq}
                    placeholder="Select an option"
                    onChange={(value) =>
                      setFormField("built_controller_project", value)
                    }
                  />
                  {"built_controller_project" in validationResults &&
                    !validationResults["built_controller_project"] && (
                      <div className="t-form-error">
                        Please select an option
                      </div>
                    )}
                </div>
              </div>
              <div className="t-form-select-field">
                <div>
                  <label>Have you ever used any PCB design tools?</label>
                  <SingleSelect
                    // defaultValue="straight"
                    style={{ width: "100%" }}
                    options={yesnoOptionsReq}
                    placeholder="Select an option"
                    onChange={(value) =>
                      setFormField("used_pcb_designing_before", value)
                    }
                  />
                  {"used_pcb_designing_before" in validationResults &&
                    !validationResults["used_pcb_designing_before"] && (
                      <div className="t-form-error">Please select a value</div>
                    )}
                </div>
              </div>
              <div className="t-form-select-field">
                <div>
                  <label>Did you prototype the board you designed ?</label>
                  <SingleSelect
                    // defaultValue="straight"
                    style={{ width: "100%" }}
                    options={yesnoOptions}
                    placeholder="Select an option"
                    onChange={(value) =>
                      setFormField("board_prototyped_before", value)
                    }
                  />
                  {"board_prototyped_before" in validationResults &&
                    !validationResults["board_prototyped_before"] && (
                      <div className="t-form-error">
                        Please select an option
                      </div>
                    )}
                </div>
              </div>
              <div className="t-form-select-field">
                <div>
                  <p>
                    If you are in final year, does your final year project
                    involve IoT ?
                  </p>
                  <SingleSelect
                    // defaultValue="straight"
                    style={{ width: "100%" }}
                    options={yesnoOptions}
                    placeholder="Select an option"
                    onChange={(value) => setFormField("fyp_IOT", value)}
                  />
                  {"fyp_IOT" in validationResults &&
                    !validationResults["fyp_IOT"] && (
                      <div className="t-form-error">
                        Please select an option
                      </div>
                    )}
                </div>
              </div>
              <div className="t-form-select-field">
                <div>
                  <p>Rate your knowledge in IoT</p>
                  <SingleSelect
                    // defaultValue="straight"
                    style={{ width: "100%" }}
                    options={ratingOptions}
                    placeholder="Select an option"
                    onChange={(value) => setFormField("iot_rating", value)}
                  />
                  {"iot_rating" in validationResults &&
                    !validationResults["iot_rating"] && (
                      <div className="t-form-error">
                        Please select an option
                      </div>
                    )}
                </div>
              </div>
              <div className="t-form-field">
                <label className="t-field-label">
                  Please tell us why you would like to join the training program
                  ?
                </label>
                <textarea
                  placeholder="Try to keep it under 500 words"
                  name="why_want_to_join"
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setFormField(event.target.name, event.target.value)
                  }
                />
                {"why_want_to_join" in validationResults &&
                  !validationResults["why_want_to_join"] && (
                    <div className="t-form-error">Field cannot be empty</div>
                  )}
              </div>
              {isLoading ? (
                <button
                  className="add-button"
                  disabled
                  style={{ cursor: "default" }}
                >
                  Loading...
                </button>
              ) : (
                <button className="add-button">Apply</button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainingForm;
