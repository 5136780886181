import { useEffect, useRef, useState } from "react";
import { authenticationApi } from "../services/Axios";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import dropdown from "../assets/icons/dropdown.png";

type OptionType = {
  name: string;
  value: string | number;
};
type SingleSelectProps = Omit<
  React.HTMLProps<HTMLSelectElement>,
  "onChange" | "value"
> & {
  options?: { value: string | number; name: string }[];
  endpoint?: string;
  extractData?: (response: AxiosResponse) => OptionType[];
  onChange?: (value: any) => void;
  value?: OptionType;
};

export default function SingleSelect({
  options,
  endpoint,
  extractData,
  ...props
}: SingleSelectProps) {
  if (endpoint && options) {
    throw new Error(
      "either one of [endpoint] or [options] has to be set, you tried setting both"
    );
  }
  if (endpoint && !extractData) {
    throw new Error(
      "when [endpoint] is not null [extractData] must be defined"
    );
  }
  const [selectOptions, setSelectOptions] = useState<OptionType[]>(
    options ?? []
  );
  const [selectedItem, setSelectedItem] = useState<OptionType | undefined>(
    props.value ?? (selectOptions && selectOptions.length > 0)
      ? undefined
      : undefined
  );

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  function hideDropdown() {
    setShowDropdown((prev) => !prev);
  }

  const queryKey = "selectItems";
  const queryFn = () => authenticationApi.get(endpoint ?? "");

  // Use a single useQuery for both lab members and sponsors
  useQuery(queryKey, queryFn, {
    onSuccess: (response) => {
      //   setItems(extractData(response));
      if (extractData) {
        setSelectOptions(extractData(response));
      }
    },
    enabled: !!endpoint,
  });

  //click outside to close dropdown
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    // <select
    // className="dipper-select"
    // //   defaultValue={props.defaultValue ?? selectOptions[0].value}
    //   value={props.value || (selectOptions.length>0 && selectOptions[0].value) || ""}
    //   onChange={props.onChange}
    // >
    //   {selectOptions.map((option, index) => (
    //     <option key={index} value={option.value}>
    //       {option.name}
    //     </option>
    //   ))}
    // </select>

    <div className="select-fields" style={props.style}>
      <div className="select-option-view">
        {!selectedItem ? (
          <span className="select-option-placeholder">
            {props.placeholder ?? "Select an item"}
            <img
              src={dropdown}
              alt="dropdown"
              onClick={hideDropdown}
              style={{ cursor: "pointer" }}
            />
          </span>
        ) : (
          <div className="item-list">
            <span>{selectedItem.name}</span>
            <img
              src={dropdown}
              alt="dropdown"
              onClick={hideDropdown}
              style={{ cursor: "pointer" }}
              className="dropdown-icon"
            />
          </div>
        )}
      </div>
      {showDropdown && (
        <div className="select-dropdown" ref={dropdownRef}>
          {selectOptions.map((item, index) => (
            <span
              key={index}
              onClick={(event) => {
                setSelectedItem(item);
                setShowDropdown(false);
                props.onChange && props.onChange(item.value);
              }}
            >
              {item.name}
            </span>
          ))}
          {selectOptions.length === 0 && <span>No options found</span>}
        </div>
      )}
    </div>
  );
}
