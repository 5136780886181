import { useSelector } from "react-redux";
import Footer from "../../../components/Footer";
import LargeScreenContainer from "../../../components/LargeScreenContainer";
import Navigation from "../../../components/Navigation";
import NewsAndEvents from "./NewsAndEvents";
import OurPartners from "./OurPartners";
import WelcomePage from "./WelcomePage";
import WhoWeAre from "./WhoWeAre";
import { RootState } from "../../../types/GlobalTypes";
import AdminNavigation from "../../Admin/components/AdminNavigation";
import { Link } from "react-router-dom";

function HomeMain() {
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  return (
    <>
      <Navigation />
      <div
        style={{
          position: "absolute",
          left: 0,
          width: "100vw",
          backgroundColor: "#d90429",
          color: "white",
          textAlign: "center",
        }}
      >
        Free IOT Training. Click <Link to="/apply">here</Link> to apply
      </div>
      {isLoggedIn && <AdminNavigation />}
      <WelcomePage />
      <LargeScreenContainer>
        <WhoWeAre />
        <NewsAndEvents />
        <OurPartners />
      </LargeScreenContainer>
      <Footer />
    </>
  );
}

export default HomeMain;
