import "../training_styles.css";
import dropdown from "../../../assets/icons/dropdown.png";
import backgroundImage from "../../../assets/images/intro-background.jpg";

import { motion } from "framer-motion";

export default function TrainingIntro() {
  return (
    <div className="training-intro">
      <img
        className="intro-background"
        src={backgroundImage}
        alt="man fixing components"
      />
      <div className="intro-content">
        <h1 style={{color: "#d90429"}}>Master IoT Hardware Development - Apply Now!</h1>
        <div style={{ lineHeight: "2rem", textAlign: "start" }}>
          <p>
            Are you a student, industry professional, or startup innovator
            looking to break into the world of IoT hardware development? DIPPER
            Lab, as part of its Knowledge Transfer Partnership (KTP)
            Collaboration with Manchester Metropolitan University and Sesi
            Technologies, is organizing a free 5-week intensive hands-on session
            in IoT Hardware Design and Prototyping for the development and IT
            ecosystem.
            <br /> This training is designed to equip you with the fundamentals
            of IoT, hardware design, and PCB prototyping—all essential skills to
            bring your tech ideas to life!
          </p>
          <br />
          {/* <br /> */}
          <p>What You&apos;ll Learn:</p>
          <ul>
            <li>
              IoT Fundamentals - Understanding sensors, microcontrollers &
              connectivity
            </li>
            <li>Hardware Design - Selecting components & designing circuits</li>
            <li>
              PCB Design & Prototyping - Schematic creation, layout, and
              fabrication
            </li>
          </ul>
          <br />
          <p>
            Whether you're new to IoT or looking to sharpen your skills, this
            hands-on training will give you the foundation needed to build
            real-world IoT solutions. Limited spots available! Apply now and
            take your first step toward becoming an IoT hardware expert.
          </p>
        </div>
        <div>
          <motion.div
            animate={{
              y: [0, -10, 0],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <a
              href="#training-form"
              style={{
                display: "inline-block",
              }}
            >
              <img src={dropdown} />
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
