import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import HomeMain from "../src/features/Home Page/components/HomeMain";
import AboutUsMain from "./features/About Page/components/AboutUsMain";
import ProjectsMain from "./features/Projects Page/components/ProjectsMain";
import PublicationsMain from "./features/Publication Page/components/PublicationsMain";
import LabMembersMain from "./features/Lab Members Page/components/LabMembersMain";
import LabMemberDetails from "./features/Lab Members Page/components/LabMemberDetails";
import EventsMain from "./features/Events Page/components/EventsMain";
import EventDetails from "./features/Events Page/components/EventDetails";
import ContactUsPage from "./features/Contact Us Page/components/ContactUsPage";
import AdminLoginPage from "./features/Admin/components/AdminLoginPage";
import { QueryClientProvider, QueryClient } from "react-query";
import AdminRegister from "./features/Admin/components/AdminRegister";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./types/GlobalTypes";
import ProjectDetails from "./features/Projects Page/components/ProjectDetails";
import Modal from "./components/Modal";
import { setAutoLogoutPopup } from "./store/reducers/AuthReducer";
import AutoLogout from "./components/AutoLogout";
import { useEffect } from "react";
import PublicationDetails from "./features/Publication Page/components/PublicationDetails";
import ForgotPassword from "./features/Admin/components/ForgotPassword";
import Training from "./features/Training/Training";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  const queryClient = new QueryClient();

  //Auto logout state
  const autoLogout = useSelector(
    (state: RootState) => state.authentication.autoLogoutPopup
  );

  //Auto logout user when token
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    const expiredTime = localStorage.getItem("expiredTime");
    if (token && expiredTime) {
      const currentTime = new Date().getTime();
      if (currentTime > Number(expiredTime)) {
        dispatch(setAutoLogoutPopup(true));
      } else {
        const timeUntilExpiration = Number(expiredTime) - currentTime;
        setTimeout(checkTokenExpiration, timeUntilExpiration);
      }
    }
  };
  checkTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      {autoLogout && (
        <Modal onClose={() => dispatch(setAutoLogoutPopup(true))}>
          <AutoLogout />
        </Modal>
      )}
      <Router>
        <Routes>
          <Route path="/" element={<HomeMain />} />
          <Route path="/about-us" element={<AboutUsMain />} />
          <Route path="/projects" element={<ProjectsMain />} />
          <Route path="/projects/details/:id" element={<ProjectDetails />} />
          <Route path="/publications" element={<PublicationsMain />} />
          <Route
            path="/publications/details/:id"
            element={<PublicationDetails />}
          />
          <Route path="/labmembers" element={<LabMembersMain />} />
          <Route
            path="/labmembers/details/:id"
            element={<LabMemberDetails />}
          />
          <Route path="/events" element={<EventsMain />} />
          <Route path="/events/details/:id" element={<EventDetails />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/apply" element={<Training />} />
          {isLoggedIn ? (
            <>
              <Route path="*" element={<Navigate to={"/"} />} />
            </>
          ) : (
            <>
              <Route path="/admin" element={<AdminLoginPage />} />
              <Route path="/admin-register" element={<AdminRegister />} />
              <Route
                path="/admin-forgot-password"
                element={<ForgotPassword />}
              />
            </>
          )}
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
